<template>
  <div class="classes" style="margin: 10px 15px 10px">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
    >
      <div class="container" v-for="item in list" :key="item">
        <div class="class_info" @click="toCharge(item)">
          <div class="info"
          >
            <van-button
              class="changeBtn"
              plain
              size="mini"
              type="primary"
              @click.stop="toStudents(item)"
              color="#82bafb"
              >查看学生</van-button
            >
            <div class="class_desc">
              <!-- <span>{{ safeName(item.name) }}</span> -->
              <span>{{ item.class_name }}</span>
              <span>{{ schoolName }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { Toast } from "vant";
import { useRouter, useRoute } from "vue-router";
import { safeName } from "../../common/js/utils";
import { setStorage, getStorage } from "../../common/js/storeUtil";
import api from "../../service/api";

export default {
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const router = useRouter(); //路由传值
    const Route = useRoute(); //获取值时使用
    const schoolName = ref("");

    onMounted(() => {
      /* eslint-disable */
      //setTitle("缴费管理");
      schoolName.value = getStorage("schoolName");
    });

    // let store = useStore();
    const onClickLeft = () => {
      router.go(-1);
    };

    function toStudents(item) {
      router.replace({
        path: "/students",
        query: {
          classId: item.id,
          className: item.class_name,
        },
      });
    }

    const onLoad = () => {
      api
        .classList({
          teacher_id: Route.query.teacherId,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            var classes = res.data.result;
            console.log("classes" + classes[0]);
            for (let i = 0; i < classes.length; i++) {
              list.value.push(classes[i]);
            }
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
          console.log("请求->", err.message);
        });
      // list.value = getStorage("students");

      // 加载状态结束
      loading.value = false;
      // 数据全部加载完成
      finished.value = true;
    };

    function toCharge(item) {
      // setStorage("student", item);
      router.push({
        path: "/charges",
        query: {
          classId: item.id,
          className: item.class_name,
        },
      });
    }

    return {
      list,
      onLoad,
      loading,
      finished,
      onClickLeft,
      toCharge,
      safeName,
      schoolName,
      toStudents,
    };
  },
};
</script>

<style lang="less" scoped>
.classes {
  min-height: 500px;
  .container {
    height: 90px;
    .class_info {
      width: 94%;
      margin: 0 auto;
      height: 80px;
      background: linear-gradient(to right, #3794fc, #82bafb);
      box-shadow: 0 2px 5px #3794fc;
      border-radius: 6px;

      .info {
        display: block;
        width: 92%;
        height: 80px;
        margin: 0 auto;
        .changeBtn {
          float: right;
          margin-top: 20px;
          margin-right: 10px;
        }
        .class_desc {
          display: inline-block;
          margin-left: 10px;
          margin-top: 20px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            display: block;
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
            text-align: left;
          }
          span:first-child {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
